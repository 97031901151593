<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="newDialog = true">
          new shop
        </v-btn>
        <ServiceNew
            :newDialog="newDialog"
            :subType="1"
            @closeNew="closeNew"
            @submitNew="submitNew"
        />
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="servicelist"
              :items-per-page="10"
              :loading="loading"
              loader-height="1"
          >
            <template v-slot:item.active="{ item }">
              <v-icon :color="item.active ? 'primary' : 'error'">
                {{ item.active ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>

            <template v-slot:item.updated="{ item }">
              {{ item.updated | myDating }}
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn
                  color="primary"
                  text
                  x-small
                  @click.stop="$set(viewDialog, item.id, true)"
              >
                view
              </v-btn>
              <CenterView
                  v-if="viewDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :viewDialog="viewDialog[item.id]"
                  @closeView="closeView"
              />

              <v-btn
                  color="success"
                  text
                  x-small
                  @click.stop="$set(editDialog, item.id, true)"
              >
                edit
              </v-btn>
              <CenterEdit
                  v-if="editDialog[item.id]"
                  :editDialog="editDialog[item.id]"
                  :myId="item.id"
                  @closeEdit="closeEdit"
                  @submitEdit="submitEdit"
              />

              <v-btn
                  color="error"
                  text
                  x-small
                  @click.stop="$set(removeDialog, item.id, true)"
              >
                remove
              </v-btn>
              <ServiceDelete
                  v-if="removeDialog[item.id]"
                  :myId="item.id"
                  :removeDialog="removeDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
              />

              <v-btn color="indigo" text x-small
                     @click.stop="$set(scheduleDialog, item.id, true)">schedule
              </v-btn>
              <MySchedule
                  v-if="scheduleDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :scheduleDialog="scheduleDialog[item.id]"
                  @closeSchedule="closeSchedule"
                  @submitSchedule="submitSchedule"
              />

              <v-btn
                  color="info"
                  text
                  x-small
                  @click.stop="$set(newshopDialog, item.id, true)"
              >
                map
              </v-btn>
              <PharmaShopNew
                  v-if="newshopDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :newshopDialog="newshopDialog[item.id]"
                  @closeMap="closeMap"
                  @submitMap="submitMap"
              />

              <v-btn
                  :to="{
                  name: 'pharma.centers.stocks',
                  params: { id: _encode(item.id), name: _encode(item.name) },
                }"
                  color="indigo"
                  text
                  x-small
              >
                stocks
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-snackbar
          v-model="snack.bar"
          :color="snack.color"
          :multi-line="snack.multiLine"
      >
        {{ snack.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import ServiceNew from "../../components/subsidiaries/centers/newone.vue";
import CenterView from "../../components/subsidiaries/centers/view.vue";
import CenterEdit from "../../components/subsidiaries/centers/edit.vue";
import ServiceDelete from "../../components/subsidiaries/centers/remove.vue";
import MySchedule from "@/components/subsidiaries/schedule/mySchedule.vue";
import PharmaShopNew from "../../components/maps/newshop.vue";

export default {
  components: {
    ServiceNew,
    ServiceDelete,
    CenterView,
    CenterEdit,
    PharmaShopNew, MySchedule
  },
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      editDialog: {},
      viewDialog: {},
      removeDialog: {},
      newshopDialog: {},
      scheduleDialog: {},
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      loading: false,
      newDialog: false,
      crumbs: [
        {
          link: true,
          text: "Dashboard",
          to: {name: "pharma.dash"},
        },
        {
          link: true,
          text: "Shops",
          to: {name: "pharma.shops"},
        },
      ],
      servicelist: [],
      headers: [
        {text: "subsidiary", value: "name"},
        {text: "description", value: "description"},
        {text: "location", value: "physical_location"},
        {text: "active", value: "active"},
        {text: "updated", value: "updated"},
        {text: "action", value: "id"},
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subsidiary
          .list()
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.servicelist = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    refresh() {
      this.list();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .create(p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.newDialog = p.state;
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .update(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers.subsidiary
          .remove(p.id)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeMap(p) {
      this.$set(this.newshopDialog, p.id, p.state);
    },
    submitMap(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .geo(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.newshopDialog, p.id, p.state);
    },
    closeSchedule(p) {
      this.$set(this.scheduleDialog, p.id, p.state);
    },
    submitSchedule(p) {
      console.log(p)
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      let self = this;
      Restful.subscribers.subsidiary
          .updateSchedule(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.scheduleDialog, p.id, p.state);
    },
  },
};
</script>
