<template>
  <v-dialog v-model="newshopDialog" scrollable persistent max-width="900">
    <v-card loader-height="2" :loading="loading">
      <v-card-title>{{ name }} Location {{ myId }}</v-card-title>
      <v-card-text>
        <!-- starts -->
        <v-row>
          <v-col cols="12" sm="12">
            <!-- <GmapMap
              ref="mapRef"
              :center="center"
              :zoom="12"
              map-type-id="terrain"
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @position_changed="updateCenter"
                @click="center = m.position"
              />
            </GmapMap> -->
            <GmapMap
              ref="mapRef"
              :center="center"
              :zoom="12"
              style="width: 100%; height: 500px"
              map-type-id="terrain"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
                @position_changed="updateCenter"
              />
            </GmapMap>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
export default {
  props: {
    newshopDialog: Boolean,
    myId: { type: Number },
    name: { type: String },
  },
  data() {
    return {
      loading: false,
      center: {},
      markers: [],
      reportedMapCenter: {},
      responseMessage: null,
    };
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo(this.center);
    });
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      let self = this;
      this.loading = true;
      Restful.subscribers.subsidiary
        .find(this.myId)
        .then((response) => {
          let data = response.data;
          console.log(data);
          let geoLat = data.geo_lat;
          let geoLng = data.geo_lng;
          if (geoLat === undefined) {
            this.myMap();
          } else {
            let myLat = geoLat;
            let myLng = geoLng;
            this.center = {
              lat: myLat,
              lng: myLng,
            };
            this.markers = [
              {
                position: {
                  lat: myLat,
                  lng: myLng,
                },
              },
            ];
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
    },
    updateCenter(latLng) {
      this.reportedMapCenter = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };
    },
    myMap() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let myLat = position.coords.latitude;
          let myLng = position.coords.longitude;
          this.center = {
            lat: myLat,
            lng: myLng,
          };

          this.markers = [
            {
              position: {
                lat: myLat,
                lng: myLng,
              },
            },
          ];
          console.log(this.markers);
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeMap", payload);
    },
    submit() {
      let geo_lat = this.reportedMapCenter.lat;
      let geo_lng = this.reportedMapCenter.lng;
      let data = { geo_lat, geo_lng };
      console.log("data: ", data);

      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitMap", payload);
    },
  },
};
</script>
