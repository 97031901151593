<template>
  <v-dialog v-model="scheduleDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update {{ name }} Schedule</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select v-model="edit.start_day" :items="days"
                      item-text="label" item-value="id" label="working week start"></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select v-model="edit.end_day" :items="days" item-text="label"
                      item-value="id" label="working week end"></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-menu
                ref="ref1"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="edit.start_time"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="edit.start_time"
                    label="Work start time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="menu"
                  v-model="edit.start_time"
                  full-width
                  @click:minute="$refs.ref1.save(edit.start_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
                ref="ref2"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="edit.end_time"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="edit.end_time"
                    label="Work end time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="menu1"
                  v-model="edit.end_time"
                  full-width
                  @click:minute="$refs.ref2.save(edit.end_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="$v.edit.$invalid" color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import moment from "moment/moment";

export default {
  props: {
    scheduleDialog: Boolean,
    myId: {type: Number, default: 0},
    name: {type: String}
  },
  mixins: [Utils, validationMixin],
  data() {
    return {
      loading: false,
      menu: null,
      menu1: null,
      ref1: null,
      ref2: null,
      edit: {
        start_day: null,
        end_day: null,
        start_time: null,
        end_time: null,
      },
      days: [
        {id: "monday", label: "monday"},
        {id: "tuesday", label: "tuesday"},
        {id: "wednesday", label: "wednesday"},
        {id: "thursday", label: "thursday"},
        {id: "friday", label: "friday"},
        {id: "saturday", label: "saturday"},
        {id: "sunday", label: "sunday"},
      ]
    };
  },
  validations: {
    edit: {
      start_day: {
        required,
      },
      end_day: {
        required,
      },
      start_time: {
        required,
      },
      end_time: {
        required,
      },
    },
  },
  created() {
    this.find();
  },
  watch: {},
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subsidiary
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.edit.end_day = response.data.end_day;
            this.edit.end_time = moment(
                response.data.end_time
            ).format("HH:mm");
            this.edit.start_day = response.data.start_day;
            this.edit.start_time = moment(
                response.data.start_time
            ).format("HH:mm");

            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeSchedule", payload);
    },
    submit() {
      let payload = {state: false, id: this.myId, data: this.edit};
      this.$emit("submitSchedule", payload);
    },
  },
};
</script>
